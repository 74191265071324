/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUsersShotwireCom = /* GraphQL */ `
  mutation CreateUsersShotwireCom(
    $input: CreateUsersShotwireComInput!
    $condition: ModelUsersShotwireComConditionInput
  ) {
    createUsersShotwireCom(input: $input, condition: $condition) {
      id
      username
      productName
      productPlanId
      productHistory
      groups
      maxUploads
      maxUploadTime
      maxConversionTimes
      totalUploads
      profilePhoto
      videoLogo
      licensingOffers
      followers
      following
      favorites
      downloads
    }
  }
`;
export const updateUsersShotwireCom = /* GraphQL */ `
  mutation UpdateUsersShotwireCom(
    $input: UpdateUsersShotwireComInput!
    $condition: ModelUsersShotwireComConditionInput
  ) {
    updateUsersShotwireCom(input: $input, condition: $condition) {
      id
      username
      productName
      productPlanId
      productHistory
      groups
      maxUploads
      maxUploadTime
      maxConversionTimes
      totalUploads
      profilePhoto
      videoLogo
      licensingOffers
      followers
      following
      favorites
      downloads
    }
  }
`;
export const deleteUsersShotwireCom = /* GraphQL */ `
  mutation DeleteUsersShotwireCom(
    $input: DeleteUsersShotwireComInput!
    $condition: ModelUsersShotwireComConditionInput
  ) {
    deleteUsersShotwireCom(input: $input, condition: $condition) {
      id
      username
      productName
      productPlanId
      productHistory
      groups
      maxUploads
      maxUploadTime
      maxConversionTimes
      totalUploads
      profilePhoto
      videoLogo
      licensingOffers
      followers
      following
      favorites
      downloads
    }
  }
`;
export const createSystemStore = /* GraphQL */ `
  mutation CreateSystemStore(
    $input: CreateSystemStoreInput!
    $condition: ModelSystemStoreConditionInput
  ) {
    createSystemStore(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      active
      groups
    }
  }
`;
export const updateSystemStore = /* GraphQL */ `
  mutation UpdateSystemStore(
    $input: UpdateSystemStoreInput!
    $condition: ModelSystemStoreConditionInput
  ) {
    updateSystemStore(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      active
      groups
    }
  }
`;
export const deleteSystemStore = /* GraphQL */ `
  mutation DeleteSystemStore(
    $input: DeleteSystemStoreInput!
    $condition: ModelSystemStoreConditionInput
  ) {
    deleteSystemStore(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      active
      groups
    }
  }
`;
export const createShotwireAssetsDelta = /* GraphQL */ `
  mutation CreateShotwireAssetsDelta(
    $input: CreateShotwireAssetsDeltaInput!
    $condition: ModelShotwireAssetsDeltaConditionInput
  ) {
    createShotwireAssetsDelta(input: $input, condition: $condition) {
      id
      assetId
      assetType
      fileName
      fileInfo
      fileSize
      highRezFileName
      highRezDirectory
      name
      realSetId
      setDate
      companyId
      caption
      headline
      instructions
      objectName
      source
      copyright
      keywords
      byline
      fixtureId
      suppCat
      captionWriter
      city
      state
      country
      countryCode
      originalTransmissionReference
      releaseDate
      createdDate
      modified
      video_format
      video_bitrate
      video_width
      video_height
      video_fps
      video_aspect
      audio_codec
      audio_format
      audio_bitrate
      audio_rate
      audio_nch
      length
      video_frames
      video_length
      audio_length
      archived
      s3srcUrl
      proxyInfo
      apiSource
      thumbNailUrl
      mp4Outputs
      mp4Urls
      hlsUrl
      dashUrl
      cmafDashUrl
      cmafDashPlaylist
      cmafHlsUrl
      cmafHlsPlaylist
      workflowStatus
      category
      searches
      uploader
      owner
      timestamp
      license
      marketplace
      srcMediainfo
    }
  }
`;
export const updateShotwireAssetsDelta = /* GraphQL */ `
  mutation UpdateShotwireAssetsDelta(
    $input: UpdateShotwireAssetsDeltaInput!
    $condition: ModelShotwireAssetsDeltaConditionInput
  ) {
    updateShotwireAssetsDelta(input: $input, condition: $condition) {
      id
      assetId
      assetType
      fileName
      fileInfo
      fileSize
      highRezFileName
      highRezDirectory
      name
      realSetId
      setDate
      companyId
      caption
      headline
      instructions
      objectName
      source
      copyright
      keywords
      byline
      fixtureId
      suppCat
      captionWriter
      city
      state
      country
      countryCode
      originalTransmissionReference
      releaseDate
      createdDate
      modified
      video_format
      video_bitrate
      video_width
      video_height
      video_fps
      video_aspect
      audio_codec
      audio_format
      audio_bitrate
      audio_rate
      audio_nch
      length
      video_frames
      video_length
      audio_length
      archived
      s3srcUrl
      proxyInfo
      apiSource
      thumbNailUrl
      mp4Outputs
      mp4Urls
      hlsUrl
      dashUrl
      cmafDashUrl
      cmafDashPlaylist
      cmafHlsUrl
      cmafHlsPlaylist
      workflowStatus
      category
      searches
      uploader
      owner
      timestamp
      license
      marketplace
      srcMediainfo
    }
  }
`;
export const deleteShotwireAssetsDelta = /* GraphQL */ `
  mutation DeleteShotwireAssetsDelta(
    $input: DeleteShotwireAssetsDeltaInput!
    $condition: ModelShotwireAssetsDeltaConditionInput
  ) {
    deleteShotwireAssetsDelta(input: $input, condition: $condition) {
      id
      assetId
      assetType
      fileName
      fileInfo
      fileSize
      highRezFileName
      highRezDirectory
      name
      realSetId
      setDate
      companyId
      caption
      headline
      instructions
      objectName
      source
      copyright
      keywords
      byline
      fixtureId
      suppCat
      captionWriter
      city
      state
      country
      countryCode
      originalTransmissionReference
      releaseDate
      createdDate
      modified
      video_format
      video_bitrate
      video_width
      video_height
      video_fps
      video_aspect
      audio_codec
      audio_format
      audio_bitrate
      audio_rate
      audio_nch
      length
      video_frames
      video_length
      audio_length
      archived
      s3srcUrl
      proxyInfo
      apiSource
      thumbNailUrl
      mp4Outputs
      mp4Urls
      hlsUrl
      dashUrl
      cmafDashUrl
      cmafDashPlaylist
      cmafHlsUrl
      cmafHlsPlaylist
      workflowStatus
      category
      searches
      uploader
      owner
      timestamp
      license
      marketplace
      srcMediainfo
    }
  }
`;
export const createShotwireAlbum = /* GraphQL */ `
  mutation CreateShotwireAlbum(
    $input: CreateShotwireAlbumInput!
    $condition: ModelShotwireAlbumConditionInput
  ) {
    createShotwireAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      members
      photos {
        items {
          id
          albumId
          owner
          members
          bucket
          labels
          iptc
          name
        }
        nextToken
      }
    }
  }
`;
export const updateShotwireAlbum = /* GraphQL */ `
  mutation UpdateShotwireAlbum(
    $input: UpdateShotwireAlbumInput!
    $condition: ModelShotwireAlbumConditionInput
  ) {
    updateShotwireAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      members
      photos {
        items {
          id
          albumId
          owner
          members
          bucket
          labels
          iptc
          name
        }
        nextToken
      }
    }
  }
`;
export const deleteShotwireAlbum = /* GraphQL */ `
  mutation DeleteShotwireAlbum(
    $input: DeleteShotwireAlbumInput!
    $condition: ModelShotwireAlbumConditionInput
  ) {
    deleteShotwireAlbum(input: $input, condition: $condition) {
      id
      name
      owner
      members
      photos {
        items {
          id
          albumId
          owner
          members
          bucket
          labels
          iptc
          name
        }
        nextToken
      }
    }
  }
`;
export const createShotwirePhoto = /* GraphQL */ `
  mutation CreateShotwirePhoto(
    $input: CreateShotwirePhotoInput!
    $condition: ModelShotwirePhotoConditionInput
  ) {
    createShotwirePhoto(input: $input, condition: $condition) {
      id
      albumId
      owner
      members
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      preview {
        key
        width
        height
      }
      labels
      iptc
      name
      album {
        id
        name
        owner
        members
        photos {
          nextToken
        }
      }
    }
  }
`;
export const updateShotwirePhoto = /* GraphQL */ `
  mutation UpdateShotwirePhoto(
    $input: UpdateShotwirePhotoInput!
    $condition: ModelShotwirePhotoConditionInput
  ) {
    updateShotwirePhoto(input: $input, condition: $condition) {
      id
      albumId
      owner
      members
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      preview {
        key
        width
        height
      }
      labels
      iptc
      name
      album {
        id
        name
        owner
        members
        photos {
          nextToken
        }
      }
    }
  }
`;
export const deleteShotwirePhoto = /* GraphQL */ `
  mutation DeleteShotwirePhoto(
    $input: DeleteShotwirePhotoInput!
    $condition: ModelShotwirePhotoConditionInput
  ) {
    deleteShotwirePhoto(input: $input, condition: $condition) {
      id
      albumId
      owner
      members
      bucket
      fullsize {
        key
        width
        height
      }
      thumbnail {
        key
        width
        height
      }
      preview {
        key
        width
        height
      }
      labels
      iptc
      name
      album {
        id
        name
        owner
        members
        photos {
          nextToken
        }
      }
    }
  }
`;
